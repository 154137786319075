import { gql } from "graphql-tag";
import {PAGINATION, CUSTOMER_USER_FIELDS } from "./Fragments";

export const GET_CUSTOMER_USER = gql`
    query GetCustomerUser($page: Int, $limit: Int, $uuid:String) {
        customer_users(limit:$limit, page:$page, customer_uuid:$uuid){
            ...Pagination
            data {
                ...CustomerFields
            }
        }
    }
    ${CUSTOMER_USER_FIELDS},
    ${PAGINATION},
`;


export default { GET_CUSTOMER_USER }