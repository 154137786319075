import { gql } from 'graphql-tag';
import { PRODUCT_FIELDS, PRODUCT_TO_CATEGORY_PAGINATION, CATEGORY_PRODUCTS_FIELDS, PAGINATION, FAVORITE_PRODUCT_FIELDS, FAVORITE_PRODUCT_PAGINATION, PRODUCT_PRICE_GROUP_FIELDS, PRODUCT_PRICE_GROUP_PAGINATION, PRODUCT_CUSTOMER_PRICE_FIELDS, PRODUCT_CUSTOMER_PRICE_PAGINATION } from './Fragments';

export const GET_PRODUCTS = gql`
    query GetProducts($page: Int, $limit: Int, $locale:String) {
        products(limit:$limit, page:$page, locale:$locale){
            ...Pagination
            data {
                ...ProductFields
            }
        }
    }
    ${PRODUCT_FIELDS},
    ${PAGINATION},
`;

export const GET_PRICE_GROUP_PRODUCTS = gql`
    query GetPriceGroupProducts($page: Int, $limit: Int, $id: Int, $locale:String) {
        price_group_products(limit:$limit, page:$page, id:$id, locale:$locale){
            ...Pagination
            data {
                ...ProductFields
            }
        }
    }
    ${PRODUCT_FIELDS},
    ${PAGINATION},
`;

export const GET_FAVORITE_PRODUCTS = gql`
    query GetFavoriteProducts($page: Int, $limit: Int, $customer_id: Int) {
        favorite_products(limit:$limit, page:$page, customer_id:$customer_id){
            ...FavoriteProductPagination
            data {
                ...FavoriteProductFields
            }
        }
    }
    ${FAVORITE_PRODUCT_FIELDS},
    ${FAVORITE_PRODUCT_PAGINATION},
`;

export const SEARCH_PRODUCTS = gql`
    query SearchProducts($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $types:String , $date_from:String , $date_to:String , $col: String, $type: String) {
        search_products(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, types:$types , date_from:$date_from , date_to:$date_to ,col:$col, type:$type){
            ...Pagination
            data {
                ...ProductFields
            }
        }
    }
    ${PRODUCT_FIELDS},
    ${PAGINATION},
`;

export const SEARCH_PRICE_GROUP_PRODUCTS = gql`
    query SearchPriceGroupProducts($page: Int, $limit: Int,$id: Int, $filter: String, $search_key: String, $locale:String, $types:String , $date_from:String , $date_to:String, $col: String, $type: String) {
        search_price_group_products(limit:$limit, page:$page, id:$id, filter:$filter, search_key:$search_key, locale:$locale, types:$types , date_from:$date_from , date_to:$date_to,col:$col, type:$type){
            ...Pagination
            data {
                ...ProductFields
            }
        }
    }
    ${PRODUCT_FIELDS},
    ${PAGINATION},
`;

export const GET_PRODUCT_FOR_RELATED = gql`
    query GetProductsForRelated($page: Int, $limit: Int) {
        products(limit: $limit, page: $page) {
            data {
                id
                description {
                    name
                }
            }
        }
    }
`;

export const EDIT_PRODUCT = gql`
    query EditProduct($id: String!) {
        edit_product(id: $id)
    }
`;

export const CUP_AGREEMENT_PRODUCT = gql`
    query GetProducts($page: Int, $limit: Int) {
        products(limit: $limit, page: $page) {
            data {
                id
                description {
                    name
                }
            }
        }
    }
`;

export const GET_CATEGORY_PRODUCTS = gql`
    query GetCategoryProducts($page: Int, $limit: Int, $locale:String, $slug:String) {
        category_products(limit:$limit, page:$page, locale:$locale, slug:$slug){
            ...Pagination
            data {
                ...CategoryProductsFields
            }
        }
    }
    ${CATEGORY_PRODUCTS_FIELDS},
    ${PRODUCT_TO_CATEGORY_PAGINATION},
`;

export const SEARCH_CATEGORY_PRODUCTS = gql`
    query SearchCategortProducts($page: Int, $limit: Int, $filter: String, $search_key: String, $locale:String, $slug: String, $col: String, $type: String) {
        search_category_products(limit:$limit, page:$page, filter:$filter, search_key:$search_key, locale:$locale, slug: $slug, col:$col, type:$type){
            ...Pagination
            data {
                ...CategoryProductsFields
            }
        }
    }
    ${CATEGORY_PRODUCTS_FIELDS},
    ${PRODUCT_TO_CATEGORY_PAGINATION},
`;

export const GET_PRODUCT_PRICE_GROUPS = gql`
    query GetProductPriceGroups($page: Int, $limit: Int, $product_id: Int) {
        product_price_groups(product_id:$product_id, limit:$limit, page:$page){
            ...ProductToPriceGroupPagination
            data {
                ...ProductToPriceGroupFields
            }
        }
    }
    ${PRODUCT_PRICE_GROUP_FIELDS},
    ${PRODUCT_PRICE_GROUP_PAGINATION},
`;

export const GET_PRODUCT_CUSTOMER_PRICES = gql`
    query GetProductCustomerPrices($page: Int, $limit: Int, $product_id: Int) {
        product_customer_prices(product_id:$product_id, limit:$limit, page:$page){
            ...ProductToCustomerPricePagination
            data {
                ...ProductToCustomerPriceFields
            }
        }
    }
    ${PRODUCT_CUSTOMER_PRICE_FIELDS},
    ${PRODUCT_CUSTOMER_PRICE_PAGINATION},
`;

export default { GET_PRODUCTS, EDIT_PRODUCT, GET_PRODUCT_FOR_RELATED, CUP_AGREEMENT_PRODUCT, SEARCH_PRODUCTS, GET_PRICE_GROUP_PRODUCTS, SEARCH_PRICE_GROUP_PRODUCTS, GET_CATEGORY_PRODUCTS, GET_PRODUCT_PRICE_GROUPS, GET_PRODUCT_CUSTOMER_PRICES };
