import { gql } from "graphql-tag";
import { CUSTOMER_USER_FIELDS } from "./Fragments";

export const CREATE_END_USER = gql`
	mutation CreateEndUser($input: EndUserInput!) {
		create_end_user(customer: $input) {
			...CustomerFields
		}
	}
	${CUSTOMER_USER_FIELDS}
`;

export const UPDATE_END_USER = gql`
	mutation UpdateEndUser($input: EndUserInput!) {
		update_end_user(customer: $input) {
			...CustomerFields
		}
	}
	${CUSTOMER_USER_FIELDS}
`;

export const DELETE_END_USER = gql`
	mutation DeleteEndUser($customer_id: Int) {
		delete_end_user(customer_id: $customer_id)
	}
`;

export default { CREATE_END_USER, UPDATE_END_USER, DELETE_END_USER };
