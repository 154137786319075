
import { Modal } from 'bootstrap';
import { defineComponent, ref, inject, watchEffect } from 'vue';
import { Apollo, Notify } from '@/core/services';
import { Input, Select } from '@/components/input-elements';
import { EditAddress } from '../interfaces/index';
import { GET_COUNTRIES } from '@/modules/common/countries/graphql/Queries';
import { UPDATE_CUSTOMER_ADDRESS } from '../graphql/Mutations';
import { GET_CUSTOMER_ADDRESSES } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../components/InnerLoader.vue';
import VueGoogleAutocomplete from 'vue-google-autocomplete';

export default defineComponent({
    name: 'edit address',
    props: {
        customerData: Object
    },
    components: {
        Input,
        Select,
        VueGoogleAutocomplete,
        InnerLoader
    },

    setup(props) {
        const modal: any = ref();
        const loading = ref(false);
        const emitter: any = inject('emitter');
        const i18n = useI18n();
        const editAddressForm = ref<null | HTMLFormElement>(null);
        const countries = ref([]) as Record<any, any>;
        const address_1Ref = ref();
        const route = useRoute();
        // Get Countries
        const getCountries = () => {
            countries.value = [];
            const countriesData = Apollo.readQuery({
                query: GET_COUNTRIES
            });

            const country = [] as Record<any, any>;
            countriesData.countries.forEach(element => {
                country.push({
                    label: element.name,
                    value: element.id,
                    locale: element.locale,
                    currency: element.currency
                });
            });

            countries.value = country;
        };
        const address = ref<EditAddress>({
            name: '',
            company: '',
            phone_number: '',
            address_1: '',
            address_2: '',
            city: '',
            postal_code: '',
            country_id: null,
            customer_id: null,
            id: null,
            default_payment: false,
            default_shipping: false
        });

        const resetForm = () => {
            getCountries();
            address.value.address_1 = '';
            emitter.emit('clearInput');
            editAddressForm.value?.resetFields();
        };

        const handlerSubmitForm = () => {
            editAddressForm.value?.validate(async (valid: boolean) => {
                if (valid) {
                    const formData = {
                        id: address.value.id,
                        name: address.value.name,
                        company: address.value.company,
                        city: address.value.city,
                        address_1: address.value.address_1,
                        address_2: address.value.address_2,
                        postal_code: address.value.postal_code,
                        phone_number: address.value.phone_number,
                        customer_id: address.value.customer_id,
                        country_id: address.value.country_id,
                        default_payment: address.value.default_payment == true ? 1 : 0,
                        default_shipping: address.value.default_shipping == true ? 1 : 0
                    };

                    loading.value = true;
                    await Apollo.mutate({
                        mutation: UPDATE_CUSTOMER_ADDRESS,
                        variables: { input: formData },
                        update: (store, { data: { update_address } }) => {
                            loading.value = false;
                            modal.value.hide();
                            emitter.emit('closeEditAddressModal');
                            Notify.success(`${i18n.t('message.RECORD_UPDATED_SUCCESSFULLY')}`);
                        }
                    }).catch(() => {
                        loading.value = false;
                    });
                }
            });
        };

        // Emitter To Open Model
        emitter.on('editAddressAction', (editAddress: Record<any, any>) => {
            resetForm();
            address.value.address_1 = '';
            (address.value.id = editAddress.id), (address.value.customer_id = editAddress.customer_id);
            (address.value.name = editAddress.name), (address.value.company = editAddress.company), (address.value.phone_number = editAddress.phone_number);
            address.value.city = editAddress.city;
            address.value.postal_code = editAddress.postal_code;
            address.value.country_id = editAddress.country_id;
            address.value.address_2 = editAddress.address_2;
            address.value.address_1 = editAddress.address_1;
            address.value.default_payment = editAddress.default_payment_address == 1 ? true : false;
            address.value.default_shipping = editAddress.default_shipping_address == 1 ? true : false;
            modal.value = new Modal(document.getElementById('modal_edit_address')) as HTMLElement;
            modal.value.show();
        });

        const setPlace_1 = (addres, placeResultData) => {
            addres.country == 'Danmark' || addres.country == 'Denmark' ? (addres.country = 'Danmark') : addres.country == 'Garmany' || addres.country == 'Germany' ? (addres.country = 'Germany') : '';

            const found = countries.value.find(ele => ele.label == addres.country);

            if (found) {
                address.value.country_id = addres?.country ? found.value : '';
                address.value.city = addres?.locality ? addres?.locality : '';
                address.value.postal_code = addres?.postal_code ? addres.postal_code : '';
                address.value.address_1 = addres?.route ? addres?.route + ' ' : '';
                address.value.address_1 ? (address.value.address_1 = addres?.street_number ? address.value.address_1 + addres?.street_number : address.value.address_1) : '';
            } else {
                address.value.address_1 = '';
                address.value.city = '';
                address.value.postal_code = '';
                Notify.error(`${i18n.t('message.WE_CANT_OPERATE_IN')} ${addres.country}`);
            }
        };

        const setPlace_2 = (addres, placeResultData) => {
            addres.country == 'Danmark' || addres.country == 'Denmark' ? (addres.country = 'Danmark') : addres.country == 'Garmany' || addres.country == 'Germany' ? (addres.country = 'Germany') : '';

            const found = countries.value.find(ele => ele.label == addres.country);

            if (found) {
                address.value.address_2 = addres?.route ? addres?.route + ' ' : '';
                address.value.address_2 ? (address.value.address_2 = addres?.street_number ? address.value.address_2 + addres?.street_number : address.value.address_2) : '';
            } else {
                address.value.address_2 = '';
                Notify.error(`${i18n.t('message.WE_CANT_OPERATE_IN')} ${addres.country}`);
            }
        };

        return {
            address,
            countries,
            editAddressForm,
            loading,
            handlerSubmitForm,
            setPlace_1,
            setPlace_2,
            address_1Ref
        };
    }
});
