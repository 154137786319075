import { gql } from "graphql-tag";

export const CUSTOMER_ADDRESSES_FIELDS = gql`
  fragment CustomerAddressFields on Address {
    id
    name
    customer_id
    company
    phone_number
    address_1
    address_2
    city
    postal_code
    country_id
    default_payment_address
    default_shipping_address
    country{
      id
      name
    }
  }
`;

export const PAGINATION = gql`
  fragment Pagination on AddressPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;

export default { CUSTOMER_ADDRESSES_FIELDS, PAGINATION};