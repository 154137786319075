
import { defineComponent, onMounted, ref, watchEffect } from "vue";
import { Apollo } from "@/core/services";
import CustomerDashboardContentLoader from "./CustomerDashboardContentLoader.vue";
import WidgetCustomerOrdersDaily from "@/components/widgets/mixed/WidgetCustomerOrdersDaily.vue";
import WidgetCustomerOrdersMonthly from "@/components/widgets/mixed/WidgetCustomerOrderMonthly.vue";
import CustomerWidgetOrders from "@/components/widgets/mixed/CustomerWidgetOrders.vue";
import { GET_WIDGETS } from "@/modules/customer/customers/graphql/Queries";
import { useRoute } from "vue-router";
import { asideTheme } from "@/core/helpers/config";
import { useI18n } from "vue-i18n";

export default defineComponent({
	name: "dashboard",
	props: {
		uuid: String,
	},
	components: {
		WidgetCustomerOrdersDaily,
		CustomerDashboardContentLoader,
		WidgetCustomerOrdersMonthly,
		CustomerWidgetOrders,
	},
	setup(props) {
		const loader = ref(false);
		const glob = ref(asideTheme);
		const i18n = useI18n();
		const widgets_data = ref({}) as Record<any, any>;
		const systemLocale = ref();
		const refresh = ref();

		watchEffect(() => {
			systemLocale.value = i18n.fallbackLocale.value;
			refresh.value = systemLocale.value+glob.value
		});
		const getWidgets = async () => {
			loader.value = true;
			await Apollo.watchQuery({
				query: GET_WIDGETS,
				fetchPolicy: "network-only",
				nextFetchPolicy: "cache-only",
				errorPolicy: "all",
				variables: {
					uuid: String(props.uuid),
				},
			}).subscribe(({ data, errors }) => {
				if (errors) {
					console.log(errors);
					loader.value = false;
				}

				widgets_data.value = JSON.parse(data.customer_widgets);
				loader.value = false;
			});
		};

		onMounted(() => {
			getWidgets();
		});

		return {
			widgets_data,
			loader,
			systemLocale,
			refresh
		};
	},
});
