
import { defineComponent, ref } from "vue";
import { getCSSVariableValue } from "@/assets/ts/_utils";
import InnerLoader from "../../InnerLoader.vue";

export default defineComponent({
	name: "CustomerWidgetOrders",
	props: {
		widgetClasses: String,
		chartColor: String,
		chartHeight: String,
		icon: String,
		title: String,
		link: String,
		titleDescription: String,
		getKey: {
			type: String,
			default: "",
		},
		background: String,
	},
	setup(props) {
		const color = ref(props.chartColor);
		const labelColor = getCSSVariableValue("--bs-" + "gray-800");
		const strokeColor = getCSSVariableValue("--bs-" + "gray-300");
		const baseColor = getCSSVariableValue("--bs-" + color.value);
		const lightColor = getCSSVariableValue("--bs-light-" + color.value);
		const chartOptions = {
			series: [
				{
					name: "Net Profit",
					data: [15, 25, 15, 40, 20, 50],
				},
			],
			chart: {
				fontFamily: "inherit",
				type: "area",
				height: props.chartHeight,
				toolbar: {
					show: false,
				},
				zoom: {
					enabled: false,
				},
				sparkline: {
					enabled: true,
				},
			},
			plotOptions: {},
			legend: {
				show: false,
			},
			dataLabels: {
				enabled: false,
			},
			fill: {
				type: "solid",
				opacity: 1,
			},
			stroke: {
				curve: "smooth",
				show: true,
				width: 3,
				colors: [baseColor],
			},
			xaxis: {
				categories: ["Feb", "Mar", "Apr", "May", "Jun", "Jul"],
				axisBorder: {
					show: false,
				},
				axisTicks: {
					show: false,
				},
				labels: {
					show: false,
					style: {
						colors: labelColor,
						fontSize: "12px",
					},
				},
				crosshairs: {
					show: false,
					position: "front",
					stroke: {
						color: strokeColor,
						width: 1,
						dashArray: 3,
					},
				},
				tooltip: {
					enabled: false,
				},
			},
			yaxis: {
				min: 0,
				max: 60,
				labels: {
					show: false,
					style: {
						colors: labelColor,
						fontSize: "12px",
					},
				},
			},
			states: {
				normal: {
					filter: {
						type: "none",
						value: 0,
					},
				},
				hover: {
					filter: {
						type: "none",
						value: 0,
					},
				},
				active: {
					allowMultipleDataPointsSelection: false,
					filter: {
						type: "none",
						value: 0,
					},
				},
			},
			tooltip: {
				style: {
					fontSize: "12px",
				},
				y: {
					formatter: function(val) {
						return "$" + val + " thousands";
					},
				},
			},
			colors: [lightColor],
			markers: {
				colors: [lightColor],
				strokeColor: [baseColor],
				strokeWidth: 3,
			},
		};
		const series = [
			{
				name: "Net Profit",
				data: [15, 25, 15, 40, 20, 50],
			},
		];
		const componentLoading = ref(false);
		return {
			chartOptions,
			series,
			componentLoading,
		};
	},
	components: { InnerLoader },
});
