
import { defineComponent, ref, watchEffect, watch } from 'vue';
import { Apollo } from '@/core/services';
import { GET_FAVORITE_PRODUCTS } from '../../../../catalog/products/graphql/Queries';
import Table from '../../../../../components/Table/Table.vue';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../../components/InnerLoader.vue';
export default defineComponent({
    props: {
        customer: Object
    },
    components: {
        Table,
        InnerLoader
    },
    setup(props) {
        const i18n = useI18n();
        const loader = ref(false);
        const system_locale = ref();
        const pagination = ref({}) as Record<any, any>;
        const customersProducts: any = ref([]);
        const clickHandler = ref() as Record<any, any>;
        const columns = ref([
            {
                label: 'message.CUSTOMER_NAME',
                key: 'user_name'
            },
            {
                label: 'message.PRODUCT_NAME',
                key: 'name'
            }
        ]);

        const FavoriteProducts = (page = 0) => {
            try {
                loader.value = true;
                const sub = Apollo.watchQuery({
                    query: GET_FAVORITE_PRODUCTS,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-only',
                    variables: {
                        customer_id: props.customer?.id,
                        page: page,
                        limit: 2
                    }
                }).subscribe(({ data, errors }) => {
                    if (errors) {
                        console.log(errors);
                        loader.value = false;
                    }
                    customersProducts.value = [];
                    customersProducts.value = data.favorite_products.data;
                    clickHandler.value = FavoriteProducts;
                    pagination.value = data.favorite_products;
                    loader.value = false;
                    sub.unsubscribe();
                });
            } catch (e) {
                console.log(e);
                loader.value = false;
            }
        };

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
            props.customer?.id ? FavoriteProducts(0) : '';
        });

        watch(
            () => props?.customer?.id,
            () => {
                FavoriteProducts(0);
            }
        );

        const handleCurrentChange = (val: number) => {
            clickHandler.value(val);
        };

        return {
            system_locale,
            columns,
            pagination,
            customersProducts,
            loader,
            handleCurrentChange
        };
    }
});
