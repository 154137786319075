import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-md-12" }
const _hoisted_3 = { class: "card" }
const _hoisted_4 = { class: "card-header card-p border-0" }
const _hoisted_5 = { class: "card-title align-items-start flex-column" }
const _hoisted_6 = { class: "card-label fw-bolder fs-3" }
const _hoisted_7 = { class: "card-body p-0" }
const _hoisted_8 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InnerLoader = _resolveComponent("InnerLoader")!
  const _component_Table = _resolveComponent("Table")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_InnerLoader, {
      loading: _ctx.loader,
      innerLoading: true
    }, null, 8, ["loading"]),
    _createVNode("div", _hoisted_1, [
      _createVNode("div", _hoisted_2, [
        _createVNode("div", _hoisted_3, [
          _createVNode("div", _hoisted_4, [
            _createVNode("h3", _hoisted_5, [
              _createVNode("span", _hoisted_6, _toDisplayString(`${_ctx.$t('message.CUP_AGREEMENT')} ${_ctx.$t('message.PRODUCT')}`), 1)
            ])
          ]),
          _createVNode("div", _hoisted_7, [
            _createVNode("div", _hoisted_8, [
              _createVNode(_component_Table, {
                loading: _ctx.loader,
                module: "CustomerDetail",
                columns: _ctx.columns,
                rows: _ctx.cup_agreement_products,
                padding: 5,
                pagination: 10
              }, {
                "cell-name": _withCtx(({row:item}) => [
                  _createTextVNode(_toDisplayString(item?.description ?  JSON.parse(item.description.name).da.name : 'N/A'), 1)
                ]),
                _: 1
              }, 8, ["loading", "columns", "rows"])
            ])
          ])
        ])
      ])
    ])
  ], 64))
}