
import { defineComponent, ref, inject, watchEffect, onMounted, onUnmounted } from 'vue';
import { useStore } from 'vuex';
import { Apollo, Notify } from '@/core/services';
import { GET_CUSTOMER_ADDRESSES, SEARCH_CUSTOMER_ADDRESSES, SYNC_CUSTOMER_ADDRESSES } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import { DELETE_CUSTOMER_ADDRESS } from '../graphql/Mutations';
import { useI18n } from 'vue-i18n';
import Table from '../../../../components/Table/Table.vue';
import Search from '../../../../components/search/Search.vue';
// import InnerLoader from "../../../../components/InnerLoader.vue";

export default defineComponent({
    props: {
        addressesList: Object,
		customerData: Object
    },
    name: 'address-list',
    components: {
        Table,
        Search
        // InnerLoader,
    },
    setup() {
        const store = useStore();
        const i18n = useI18n();
        const emitter: any = inject('emitter');
        const loader = ref(false);
        const addresses = ref([]) as Record<any, any>;
        const pagination = ref({}) as Record<any, any>;
        const customers = ref({}) as Record<any, any>;
        const route = useRoute();
        const uuid = String(route.params.uuid);
        const system_locale = ref();
        const clickHandler = ref() as Record<any, any>;
        const searchObservable: any = ref(null);
        const observable: any = ref(null);
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
            {
                label: 'message.COMPANY',
                key: 'company'
            },
            {
                label: 'message.CITY',
                key: 'city'
            },
            {
                label: 'message.POSTAL_CODE',
                key: 'postal_code'
            },
            {
                label: 'message.COUNTRY',
                key: 'country'
            },
            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        const getAddresses = (page = 1, uuid: any = 0) => {
            if (searchObservable.value != null) {
                searchObservable.value?.unsubscribe();
                searchObservable.value = null;
            }
            loader.value = true;
            observable.value = Apollo.watchQuery({
                query: GET_CUSTOMER_ADDRESSES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-and-network',
                variables: {
                    uuid: String(uuid),
                    page: page,
                    limit: 10
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                addresses.value = data.customer_addresses.data;
                clickHandler.value = getAddresses;
                pagination.value = data.customer_addresses;
                loader.value = false;
                observable.value?.unsubscribe();
            });
        };

        const searchHandler = (page = 1, uuid = String(route.params.uuid)) => {
            if (observable.value != null) {
                observable.value?.unsubscribe();
                observable.value = null;
            }
            searchObservable.value?.unsubscribe();
            const searchData = store.getters.getSearchData;
            loader.value = true;
            searchObservable.value = Apollo.watchQuery({
                query: SEARCH_CUSTOMER_ADDRESSES,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'network-only',
                variables: {
                    page: page,
                    limit: 10,
                    search_key: searchData.data.input,
                    locale: system_locale.value,
                    uuid: uuid
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }

                clickHandler.value = searchHandler;
                addresses.value = data.search_customer_addresses.data;
                pagination.value = data.search_customer_addresses;
                loader.value = false;
            });
        };

        watchEffect(() => {
            customers.value = store.getters?.getUser;
            system_locale.value = i18n.fallbackLocale.value;
            emitter.on('closeEditAddressModal', () => {
                getAddresses(1, String(route.params.uuid));
            });
        });

        const syncAddress = () => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_SYNC_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true
                    observable.value = Apollo.watchQuery({
                        query: SYNC_CUSTOMER_ADDRESSES,
                        errorPolicy: 'all',
                        fetchPolicy: 'network-only',
                        nextFetchPolicy: 'cache-and-network',
                        variables: {
                            uuid: String(uuid)
                        }
                    }).subscribe(({ data, errors }) => {
                        if (errors) {
                            loader.value = false;
                        }
                        addresses.value = data.sync_customer_addresses.data;
                        pagination.value = data.sync_customer_addresses;
                        loader.value = false;
                        observable.value?.unsubscribe();
                        Notify.success(i18n.t('message.RECORD_SYNCHED_SUCCESSFULLY'));
                    });
                })
                .catch(e => {
                    console.log(e);
                    loader.value = false;
                });
        };

        const addAddress = () => {
            emitter.emit('addAddressAction');
        };

        const handleEdit = edit => {
            emitter.emit('editAddressAction', edit);
        };

        const handleDelete = (id: number) => {
            store.getters.appInstance.$messageBox
                .confirm(`${i18n.t('message.ARE_YOU_SURE_TO_DELETE_RECORD')}?`, i18n.t('message.INFO'), {
                    confirmButtonText: i18n.t('message.OK'),
                    cancelButtonText: i18n.t('message.CANCEL'),
                    type: 'info'
                })
                .then(async () => {
                    loader.value = true;
                    await Apollo.mutate({
                        mutation: DELETE_CUSTOMER_ADDRESS,
                        variables: {
                            id: id
                        },
                        update: (store, { data: { delete_address } }) => {
                            if (searchObservable.value != null) searchHandler(pagination.value.current_page);
                            else getAddresses(pagination.value.current_page, String(route.params.uuid));
                            loader.value = false;
                        }
                    });
                    Notify.success(i18n.t('message.RECORD_DELETED_SUCCESSFULLY'));
                })
                .catch(e => {
                    console.log(e);
                    loader.value = false;
                });
        };

        const handleCurrentChange = number => {
            getAddresses(number, String(route.params.uuid));
        };

        onUnmounted(() => {
            observable.value?.unsubscribe();
            searchObservable.value?.unsubscribe();
        });

        getAddresses(1, String(route.params.uuid));

        return {
            addresses,
            customers,
            pagination,
            columns,
            loader,
            uuid,
            clickHandler,
            handleEdit,
            handleDelete,
            handleCurrentChange,
            addAddress,
            getAddresses,
            searchHandler,
            syncAddress
        };
    }
});
