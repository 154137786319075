
import CustomerDetail from "../components/CustomerDetail.vue";
import { defineComponent, onMounted } from "vue";
import { setPageHeader } from "@/core/helpers/toolbar";

export default defineComponent({
	components: {
		CustomerDetail,
	},
	setup() {
		onMounted(() => {
			setPageHeader({
				title: "message.CUSTOMER message.DETAIL",
				actionButton: null,
				breadCrumbs: [
					{ name: "message.HOME", link: "/dashboard" },
					{ name: "message.CUSTOMERS", link: "#" },
					{ name: "message.CUSTOMERS", link: "/customer/lists" },
					{ name: "message.CUSTOMER message.DETAIL" },
				],
			});
		});
	},
});
