
import { defineComponent, ref, onMounted, computed, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { Apollo } from '@/core/services';
import { GET_CUSTOMER_INFO_DETAIL } from '../graphql/Queries';
import { useRoute } from 'vue-router';
import CustomerOrders from '../components/partials/CustomerOrders.vue';
import CustomerCart from '../components/partials/CustomerCart.vue';
import CustomerFavoriteProducts from '../components/partials/CustomeFavoriteProducts.vue';
import CupAgreementProducts from '../components/partials/CupAgreementProducts.vue';
import Addresses from '../../Addresses/views/Addresses.vue';
import EndUser from '../../end-user/views/EndUser.vue';
import InnerLoader from '../../../../components/InnerLoader.vue';
import { generateAvatar } from '@/core/helpers/generateAvatar';
import CustomerDashboard from './partials/CustomerDashboard.vue';
import CustomerDetailContentLoader from './CustomerDetailContentLoader.vue';

export default defineComponent({
    components: {
        CustomerOrders,
        CustomerFavoriteProducts,
        CupAgreementProducts,
        CustomerCart,
        Addresses,
        EndUser,
        InnerLoader,
        CustomerDashboard,
        CustomerDetailContentLoader
    },
    name: 'address-list',
    setup() {
        const store = useStore();
        const loader = ref(false);
        const route = useRoute();
        const customer = ref({}) as Record<any, any>;
        const customerAbilities = ref({}) as Record<any, any>;
        const uuid = ref(route?.params?.uuid);
        const customerOrdersTab = ref(false);
        const customerAddressTab = ref(false);
        const endUsersTab = ref(false);
        const activeTab = ref('first');
        const cup_agreement_products = ref([]) as Record<any, any>;

        const getCustomerInfo = (page = 0, uuid: any = 0) => {
            loader.value = true;
            Apollo.watchQuery({
                query: GET_CUSTOMER_INFO_DETAIL,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    uuid: String(uuid)
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                customerAbilities.value = JSON.parse(data?.customer_info?.user?.abilities).filter(
                    ability => ability.name != 'view_users_list' && ability.name != 'edit_user' && ability.name != 'delete_user' && ability.name != 'add_user'
                );
                customer.value = data?.customer_info;
                if (customer.value?.in_cup_agreement) {
                    if (customer.value?.cup_agreements.length > 0) {
                        cup_agreement_products.value = customer.value?.cup_agreements;
                    }
                }
                loader.value = false;
            });
        };
        const companyLogo = (name = '') => {
            return generateAvatar(name);
        };

        watchEffect(() => {
            if (activeTab.value == 'second' && !customerOrdersTab.value) {
                customerOrdersTab.value = true;
            } else if (activeTab.value == 'third' && !customerAddressTab.value) {
                customerAddressTab.value = true;
            } else if (activeTab.value == 'fourth' && !endUsersTab.value) {
                endUsersTab.value = true;
            }
        });

        onMounted(() => {
            getCustomerInfo(0, String(route.params.uuid));
            route.params.orderTab == 'true' ? (activeTab.value = 'second') : '';
        });
        const me = computed(() => {
            return store.getters.getUser;
        });

        return {
            customer,
            customerAbilities,
            loader,
            cup_agreement_products,
            me,
            uuid,
            customerOrdersTab,
            activeTab,
            customerAddressTab,
            endUsersTab,
            companyLogo
        };
    }
});
