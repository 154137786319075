import { gql } from "graphql-tag";

export const CUSTOMER_USER_FIELDS = gql`
  fragment CustomerFields on Customer {
    id
    uuid
    phone_number
    user {
      id
      uuid
      name
      email
      status
      country_id
      abilities
      image {
        id
        file_path
      }
      created_at
    }
  }
`;

export const PAGINATION = gql`
  fragment Pagination on CustomerPagination {
    total
    per_page
    current_page
    from
    to
    last_page
    has_more_pages
  }
`;


export default { CUSTOMER_USER_FIELDS, PAGINATION}