
import { defineComponent, ref } from 'vue';
import Table from '../../../../../components/Table/Table.vue';
import InnerLoader from '../../../../../components/InnerLoader.vue';
export default defineComponent({
    props: {
        cup_agreement_products: Object
    },
    components: {
        Table,
        InnerLoader
    },
    setup(props) {
        const loader = ref(false);
        const columns = ref([
            {
                label: 'message.NAME',
                key: 'name'
            },
        ]);

        return {
            columns,
            loader
        };
    }
});
