
import { defineComponent, onMounted, ref, watchEffect } from 'vue';
import { useI18n } from 'vue-i18n';
import { Apollo } from '@/core/services';
import { CUSTOMER_ORDER } from '../../graphql/Queries';
import { useRoute } from 'vue-router';
import Table from '../../../../../components/Table/Table.vue';
import { useRouter } from 'vue-router';
// import InnerLoader from "../../../../../components/InnerLoader.vue";

export default defineComponent({
    components: {
        Table
        // InnerLoader,
    },

    setup() {
        const i18n = useI18n();
        const route = useRoute();
        const loader = ref(false);
        const pagination = ref({}) as Record<any, any>;
        const uuid = ref(route.params.uuid);
        const orders = ref([]) as Record<any, any>;
        const router = useRouter();
        const system_locale = ref();
        const columns = ref([
            {
                label: 'message.ORDER_BY',
                key: 'name'
            },
            {
                label: 'message.STATUS',
                key: 'status'
            },
            {
                label: 'message.E_CONOMIC',
                key: 'economic'
            },
            {
                label: 'message.SHIPMENT_STATUS',
                key: 'shipment'
            },
            {
                label: 'message.TOTAL',
                key: 'total',
                textAlignment: 'end'
            },

            {
                label: 'message.ACTIONS',
                key: 'actions',
                textAlignment: 'center'
            }
        ]);

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
        });

        const CustomerOrders = (page = 0) => {
            loader.value = true;
            Apollo.watchQuery({
                query: CUSTOMER_ORDER,
                errorPolicy: 'all',
                fetchPolicy: 'network-only',
                nextFetchPolicy: 'cache-only',
                variables: {
                    page: page,
                    limit: 10,
                    uuid: uuid.value
                }
            }).subscribe(({ data, errors }) => {
                if (errors) {
                    loader.value = false;
                }
                orders.value = data.customer_orders.data;
                pagination.value = data.customer_orders;
                loader.value = false;
            });
        };

        const handleView = (order: Record<any, any>) => {
            router.push({ name: 'order_details', params: { uuid: order.uuid } });
        };

        onMounted(() => {
            uuid.value = route.params.uuid;
        });

        CustomerOrders(0);

        const handleCurrentChange = number => {
            CustomerOrders(number);
        };

        return {
            orders,
            pagination,
            columns,
            system_locale,
            loader,
            handleCurrentChange,
            handleView
        };
    }
});
