
import { defineComponent } from 'vue';
import EndUser from '../components/EndUser.vue';
import AddEndUser from '../components/AddEndUser.vue';
import EditEndUser from '../components/EditEndUser.vue';
export default defineComponent({
    props: {
        customerAbilities: Object,
        customer: Object
    },
    components: {
        EndUser,
        AddEndUser,
        EditEndUser
    }
});
