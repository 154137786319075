
import { defineComponent } from "vue";
import AddressesList from "@/modules/customer/Addresses/components/AddressesList.vue";
import AddAddress from "@/modules/customer/Addresses/components/AddAddress.vue";
import EditAddress from "@/modules/customer/Addresses/components/EditAddress.vue";

export default defineComponent({
	name: "customer-address",
	props: {
		customerAbilities: Object,
		customer: Object,
	},
	components: {
		AddAddress,
		EditAddress,
		AddressesList,
	},
});
