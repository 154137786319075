import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EndUser = _resolveComponent("EndUser", true)!
  const _component_AddEndUser = _resolveComponent("AddEndUser")!
  const _component_EditEndUser = _resolveComponent("EditEndUser")!

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_EndUser, { customerData: _ctx.customer }, null, 8, ["customerData"]),
    _createVNode(_component_AddEndUser, {
      customerAbilities: _ctx.customerAbilities,
      customerData: _ctx.customer
    }, null, 8, ["customerAbilities", "customerData"]),
    _createVNode(_component_EditEndUser, {
      customerAbilities: _ctx.customerAbilities,
      customerData: _ctx.customer
    }, null, 8, ["customerAbilities", "customerData"])
  ], 64))
}