
import { defineComponent, ref, watchEffect, watch } from 'vue';
import { ACTIVE_CART } from '../../../../customer/customers/graphql/Queries';
import { Apollo } from '@/core/services';
import Table from '../../../../../components/Table/Table.vue';
import { useI18n } from 'vue-i18n';
import InnerLoader from '../../../../../components/InnerLoader.vue';
export default defineComponent({
    props: {
        cartLists: Object,
        customer: Object
    },
    components: {
        Table,
        InnerLoader
    },
    setup(props) {
        const i18n = useI18n();
        const loader = ref(false);
        const system_locale = ref();
        const pagination = ref({}) as Record<any, any>;
        const activeCartProducts: any = ref([]);
        const clickHandler = ref() as Record<any, any>;
        const columns = ref([
            {
                label: 'message.CUSTOMER_NAME',
                key: 'user_name'
            },
            {
                label: 'message.PRODUCT_NAME',
                key: 'name'
            },
            {
                label: 'message.QUANTITY',
                key: 'quantity'
            }
        ]);

        const ActiveCartProducts = (page = 0) => {
            try {
                loader.value = true;
                const sub = Apollo.watchQuery({
                    query: ACTIVE_CART,
                    errorPolicy: 'all',
                    fetchPolicy: 'network-only',
                    nextFetchPolicy: 'cache-only',
                    variables: {
                        customer_id: props.customer?.id,
                        page: page,
                        limit: 2
                    }
                }).subscribe(({ data, errors }) => {
                    if (errors) {
                        loader.value = false;
                    }
                    activeCartProducts.value = [];
                    activeCartProducts.value = data?.active_cart?.data;
                    clickHandler.value = ActiveCartProducts;
                    pagination.value = data?.active_cart;
                    loader.value = false;
                    sub.unsubscribe();
                });
            } catch (e) {
                console.log(e);
                loader.value = false;
            }
        };

        watchEffect(() => {
            system_locale.value = i18n.fallbackLocale.value;
            props.customer?.id ? ActiveCartProducts(0) : '';
        });

        watch(
            () => props.customer?.id,
            () => {
                ActiveCartProducts(0);
            }
        );

        const handleCurrentChange = (val: number) => {
            clickHandler.value(val);
        };

        return {
            system_locale,
            columns,
            pagination,
            activeCartProducts,
            loader,
            handleCurrentChange
        };
    }
});
