import { gql } from "graphql-tag";
import { CUSTOMER_ADDRESSES_FIELDS } from "./Fragments";

export const CREATE_CUSTOMER_ADDRESS = gql`
	mutation CreateCustomer($input: AddressInput!) {
		create_address(address: $input) {
			...CustomerAddressFields
		}
	}
	${CUSTOMER_ADDRESSES_FIELDS}
`;

export const UPDATE_CUSTOMER_ADDRESS = gql`
	mutation UpdateCustomer($input: AddressInput!) {
		update_address(address: $input) {
			...CustomerAddressFields
		}
	}
	${CUSTOMER_ADDRESSES_FIELDS}
`;

export const DELETE_CUSTOMER_ADDRESS = gql`
	mutation DeleteCustomerAddress($id: Int!) {
		delete_address(id: $id) {
			...CustomerAddressFields
		}
	}
	${CUSTOMER_ADDRESSES_FIELDS}
`;

export default { CREATE_CUSTOMER_ADDRESS, UPDATE_CUSTOMER_ADDRESS, DELETE_CUSTOMER_ADDRESS };
